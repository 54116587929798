import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const GiftingImage = ({ imgName, alt = "" }) => (
  <StaticQuery
    query={graphql`
      query GiftingImages {
        allFile(
          filter: { absolutePath:{regex:"/(gifting)\/.*/"}}
          sort: { order: ASC, fields: base }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 265) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allFile.edges.find(
        edge => edge.node.childImageSharp.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Img fluid={image.node.childImageSharp.fluid} alt={alt} />
    }}
  />
)

export default GiftingImage
