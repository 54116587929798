import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Tabs, Tab, Row, Col } from "react-bootstrap"
import GiftingImage from "../components/gifting-image"

const GiftingPageTemplate = ({ about }) => (
  <Layout>
    <Helmet>
      <title>
        Corporate Gifts Online: Buy Customized Diwali Gifting Online At Best
        Price In Mumbai.
      </title>
      <meta
        name="description"
        content="Buy corporate &amp; customized gifts online for Diwali at Deliciae, Mumbai. Our premium range of gifting items, available at our shopping site that suits company’s needs. Our cake &amp; gifting shops at Bandra, Powai &amp; Juhu in Mumbai."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Corporate Gifts Online: Buy Customized Diwali Gifting Online At Best Price In Mumbai."
      />
      <meta
        property="og:description"
        content="Buy corporate &amp; customized gifts online for Diwali at Deliciae, Mumbai. Our premium range of gifting items, available at our shopping site that suits company’s needs. Our cake &amp; gifting shops at Bandra, Powai &amp; Juhu in Mumbai."
      />
      <meta
        property="og:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="Corporate Gifts Online: Buy Customized Diwali Gifting Online At Best Price In Mumbai."
      />
      <meta
        property="twitter:description"
        content="Buy corporate &amp; customized gifts online for Diwali at Deliciae, Mumbai. Our premium range of gifting items, available at our shopping site that suits company’s needs. Our cake &amp; gifting shops at Bandra, Powai &amp; Juhu in Mumbai."
      />
      <meta
        property="twitter:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
    </Helmet>
    <div className={"light-bg gifting-page"}>
      <div className={"container"}>
        <Row>
          <Col lg="12">
            <h1 className={"text-center my-5"}>Gifting by Deliciae</h1>
            <p>
              At Deliciae, we love to work with companies to create elegant yet
              impactful packaging that resonates with the brand. It’s simple -
              we’re obsessed with design.
            </p>
            <p>
              Let us help you curate a range of handcrafted signature products
              in beautiful gift boxes to make a complete gift for any occasion.
            </p>
            <p>
              We aim to create everlasting memories for both, our clients and
              the recipients of our gift boxes.
            </p>

            <Tabs
              variant="pills"
              defaultActiveKey="product_range"
              id="gifting-tabs"
            >
              <Tab eventKey="occasions" title="Occasions" className={"py-3"}>
                <p>
                  Choose from our range of beautifully designed packaging, or
                  have us customise the packaging as per your needs, for a
                  variety of occasions.
                </p>
                <strong>Corporate Gifting </strong>
                <p>
                  We offer gifts for occasions such as Diwali customized to your
                  company design and uniquely put together to suit your taste.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Corporate Gifting.jpg"
                      alt="Corporate Gifting"
                    />
                  </Col>
                </Row>
                <br />
                <strong>Wedding Gifting</strong>
                <p>
                  Customise a gift box that pairs well with your wedding cards
                  to put together an exquisite invitation or choose from
                  luxurious goodies for your wedding guests that make a sweet
                  addition to your big day
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Wedding Gifting.jpeg"
                      alt="Wedding Gifting"
                    />
                  </Col>
                </Row>
                <br />
                <strong>Birth Announcement Gifting</strong>
                <p>
                  Create &amp; customize gift boxes to introduce your bundle of
                  joy to the world. Other occasions Be it a special event,
                  launch or anything else, we’ll have you covered.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Birth Announcement Gifting.jpeg"
                      alt="Birth Announcement Gifting"
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="product_range"
                title="Product Range"
                className={"py-3"}
              >
                <p>
                  <strong>Handmade Truffles</strong>
                  <br />
                  Our gorgeous handcrafted chocolate based confections taste
                  rich and indulgent and come with a silky, creamy centre.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Handmade Truffles.jpg"
                      alt="Handmade Truffles"
                    />
                  </Col>
                </Row>
                <br />
                <p>
                  <strong>Dry Fruit Dates</strong>
                  <br />
                  Our signature collection of Dry Fruit Dates are These are a
                  superfood with a healthy but delicious twist. They’re center
                  filled, dipped and coated which makes every bite a burst of
                  flavour.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Dry Fruit Dates.jpg"
                      alt="Dry Fruit Dates"
                    />
                  </Col>
                </Row>

                <br />
                <p>
                  <strong>Almond Marzipans</strong>
                  <br />
                  These are sweet little confections rich in almond flavour,
                  mildly flavored, that you can’t get enough of. The colored
                  chocolate coating makes them a stunning gift.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Almond Marzipans.jpg"
                      alt="Almond Marzipans"
                    />
                  </Col>
                </Row>
                <br />
                <p>
                  <strong>Almond Rochers</strong>
                  <br />
                  Packed with almonds, these sugar free treats have the perfect
                  nutty crunch.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Almond Rochers.jpg"
                      alt="Almond Rochers"
                    />
                  </Col>
                </Row>

                <br />
                <p>
                  <strong>Macarons</strong>
                  <br />
                  Our authentic French Macarons are crunchy &amp; moist and melt
                  in your mouth. They make an elegant and luscious gift.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage imgName="Macarons.jpg" alt="Macarons" />
                  </Col>
                </Row>

                <br />
                <p>
                  <strong>Biscottis</strong>
                  <br />
                  The iconic Italian Cookie makes a great sweet, crunchy snack.
                  Its chocolate &amp; dry fruit combination makes it nothing
                  short of perfect.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage imgName="Biscottis.jpg" alt="Biscottis" />
                  </Col>
                </Row>

                <br />
                <p>
                  <strong>Health Bars</strong>
                  <br />
                  Packed with seeds, nuts and dry fruits, these nutrition dense
                  treats are sugar free and make a thoughtful, healthy gift.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage imgName="Health Bars.jpg" alt="Health Bars" />
                  </Col>
                </Row>

                <br />

                <p>
                  <strong>Florentines</strong>
                  <br />
                  The florentine bars are an irresistible pairing of nuts and
                  dry fruits with a hint of sweetness, making them a delicious
                  gift.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage imgName="Florentines.jpg" alt="Florentines" />
                  </Col>
                </Row>
                <br />
                <p>
                  <strong>Gift Hampers</strong>
                  <br />A Deliciae gift hamper will spoil you for choice!
                  Contact us to customise your hampers with our products &amp;
                  more.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Gift Hampers.jpg"
                      alt="Gift Hampers"
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="customizations"
                title="Customizations"
                className={"py-3"}
              >
                <p>
                  Here’s how you can add a lovely, personal touch to your gifts
                </p>
                <br />
                <strong>Box Designs</strong>
                <p>
                  Incorporate themes &amp; colours based on the occasion, or add
                  a company or wedding logo for a personal touch
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage imgName="Box Designs.jpg" alt="Box Designs" />
                  </Col>
                </Row>
                <br />
                <strong>Gold Plated Name Tags</strong>
                <p>
                  Add a recipient’s name, or a short message on your gift box,
                  all plated in gold.
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Gold Plated Name Tags.jpeg"
                      alt="Gold Plated Name Tags"
                    />
                  </Col>
                </Row>
                <br />
                <strong>Printed Butter Paper</strong>
                <p>
                  Customise your box further by adding a message, logo or any
                  design on your butter paper
                </p>
                <Row>
                  <Col lg="3">
                    <GiftingImage
                      imgName="Printed Butter Paper.jpeg"
                      alt="Printed Butter Paper"
                    />
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  </Layout>
)

GiftingPageTemplate.propTypes = {
  about: PropTypes.string,
}

const GiftingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return <GiftingPageTemplate about={frontmatter.about} />
}

GiftingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GiftingPage
export const query = graphql`
  query GiftingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        about
      }
    }
  }
`
